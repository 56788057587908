.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Adjust as needed */
    padding: 20px; /* Adjust the padding around the container */
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .gallery-card {
    flex: 0 0 22%; /* Adjust the width as needed */
    margin: 10px; /* Adjust the margin as needed */
    text-align: center;
    overflow: hidden; /* Hide overflow for images with different aspect ratios */
    border-radius: 8px; /* Add border-radius for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow for a subtle effect */
    height: 200px;
    cursor: pointer;
  }
  
  .gallery-image {
    width: 100%; /* Adjust the width as needed */
    height: 100%;
    border-radius: 8px; /* Add border-radius for rounded corners */
    object-fit: scale-down;
  }
  
  .gallery-card h4 {
    margin-top: 10px; /* Adjust the margin for the name */
  }
  
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .modal-content {
    max-width: 60%;
    max-height: 60%;
    overflow: hidden;
    position: relative;
  }
  
  .modal-image {
    width: 100%;
    height: auto;
  }
  
  .close {
    color: #fff;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
    cursor: pointer;
  }
  