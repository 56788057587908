.pcMessageContainer {
    background-color: #DCDCDC;
    padding: 20px;
    margin: 20px 0;
  }
  
  .pcMessage {
    text-align: center;
    font-weight: bold;
  }

  .pcmsg{
    color: orange;
  }

  .signature {
    text-align: right;
    margin-top: 20px;
    font-style: italic;
  }

  .borderedBox {
    border: 2px solid black;
    padding: 10px;
    margin-top: 20px;
    width: 300px; /* Reduce the width as per your requirement */
    margin: 0 auto; /* Center the div horizontally */
  }

  .borderedBox h2 {
    text-align: center;
    font-size: 24px; /* Increase font size as per your requirement */
  }

  .borderedBox p {
    text-align: center;
    font-size: 18px; /* Increase font size as per your requirement */
    color: black; /* Set the text color to black */
  }

  .borderedBox p a {
    color: inherit; /* Ensure the link color remains the same as the text */
  }
  