.ABOUT-Main-VM {
  display: flex;
  padding-left: 40px;
  padding-top: 10px;
  margin-bottom: 50px;
}

.ABOUTallLeftMenu {
  margin-top: 10px;
  margin-right: 60px;
}

.ABOUTallLeftMenu a {
  text-decoration: none;
  color: white;
  display: block;
  font-weight: bold;
}

/* .ABOUTmainContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .ABOUT-allLeftMenu{
    margin-top: 10px;
    margin-right: 60px;
  }


  
  .allRightContentHeading {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #2e0669;
  }
  
  /* Button structure */
.ABOUTallLeftMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Default button style */
.ABOUTallLeftMenu .menuButton {
  width: 240px !important;
  height: 60px !important;
  flex-shrink: 0;
  border-radius: 7px !important;
  box-shadow: 0px 4px 22px 0px rgba(123, 124, 134, 0.36) !important;
  padding: 10px !important;
  text-align: center !important;
  text-decoration: none !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid #035a5a !important;
  background: #fff !important;
  color: black !important;
}


/* Hover state */
.ABOUTallLeftMenu .menuButton:hover {
  background-color: #035a5a !important;
  color: #fff !important;
}

/* Selected state */
.ABOUTallLeftMenu .menuButton.active {
  background: #035a5a !important;
  color: white !important;
}

.allRight-ContentProfile {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}



.a {
  font-weight: bold;
  font-size: 15px;
  color: rgb(20, 6, 63);
}

@media screen and (max-width: 600px) {
  .ABOUT-Main-VM {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
  }

  .ABOUT-allLeftMenu {
    flex-direction: row;
  }

  .ABOUT-allLeftMenu .menuButton {
    width: 110px;
  }
}
