.contact-card {
  /* height: 30vh; */
  width: 25vw;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-inline: 20px; */
  padding-top: 20px;
  margin-left: 30px;
  /* margin-left: 10px; */
}
.contact-card-place {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* ContactUs.css */

.contact-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  gap: 20px;

}

.map-container {
  flex: content; 
  margin-left: 20px;
  height: 40vh;
}

.contact-details {
  /* flex: 1; Takes up 50% of the screen width */
  /* margin: 16px; */
  display: flex;
  flex-direction: row;
  padding-left: 10px;
 
}

/* Styles for contact details elements */
.contact-details h2 {
  font-size: 20px;
}

.contact-details p {
  font-size: 16px;
}

.additional-contact-details {
  margin-bottom: 20px;
  flex-direction: row;
  grid-template-columns: repeat(3,1fr);
}

.additional-details{
  height: 20vh;
}
.additional-contact-details {
  display: block;
}

/* .fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
} */

/* .visible {
  opacity: 1;
  transform: translateY(0);
} */

.college-section,
.additional-contact-section {
  margin-bottom: 20px;
}

.contact-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.member {
  border: 1px solid #ddd;
  cursor: pointer;
  /* transition: transform 0.2s ease; */
  height: 40vh;
  padding: 20px;
}
/* 
.member:hover {
  transform: scale(1.05);
} */

.member h3 {
  margin-bottom: 12px;
}

.paper-details{
  height: 20vh;
  width: max-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
}


.cd{
  color: rgb(20, 6, 63);
}