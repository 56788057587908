/* Component level styles */
.notificationsComponent {
    padding: 20px;
  }
  
  /* Container level styles */
  .notificationsContainer {
    display: flex;
    flex-direction: column;
  }
  
  /* Notification item styles */
  .notificationItem {
    margin-bottom: 15px;
  }
  
  /* Notification box styles */
  .notificationBox {
    border: 1px solid #ddd;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Date-time container styles */
  .dateTimeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Date, month, and year styles */
  .dateDiv, .monthDiv, .yearDiv {
    font-weight: bold;
  }
  
  /* Notification description styles */
  .updateDescription {
    flex: 1;
    margin: 0 15px;
    color: #2e0669; /* Change text color to blue */
  }
  
  /* Notification button styles */
  .notificationButton {
    background-color: #f7f1f2;
    color: white;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
  }
  
  /* Notification link styles */
  .notificationLink {
    color: #2e0669;
    text-decoration: none;
    font-weight: bold;
  }
  
  /* Non-link content styles */
  .nonLinkContent {
    /* Add styles for non-link content */
    color: #333;
  }
  
  /* "New" indicator image styles */
  .newIndicator {
    width: 30px;
    height: 30px;
  }
  
  /* Separator styles */
  .updateDivSeparator {
    border-top: 1px solid #ddd;
    margin-top: 10px;
  }

  .dateWrapper {
    border: 2px solid #2e0669; /* Set the border color (blue) and width */
    padding: 20px; /* Add padding to give some space around the date */
    border-radius: 5px; /* Optional: Add border-radius for rounded corners */
  }