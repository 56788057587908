.ABOUT-Main{
    display: flex;
    padding-left: 40px;
    padding-top: 10px;
  }
  
  .ABOUTallLeftMenu{
    margin-top: 10px;
    margin-right: 60px;
  }
  
  
  .ABOUTallLeftMenu .menubutton {
    
    display: flex;
    flex-direction: column;
    background-color: grey;
    color: white;
    text-decoration: none;
    width: 300px; 
    height: 60px; /* Set a fixed height for your buttons */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-weight: bold;
    margin-left: 13%;
  }
  
  /* Hover state */
  .ABOUTallLeftMenuAOP .menubutton:hover {
    background-color: #2e0669;
  }
  
  /* Selected state */
  .ABOUTallLeftMenuAOP .menubutton.active {
    background-color: #2e0669;
  }
  
  
  .ABOUTallLeftMenu a {
    text-decoration: none;
    color: white;
    display: block;
    font-weight: bold;
  }
  
  .ADimg img {
    width: 300px; /* Set the width as per your design */
    height: auto;
  }
  
  .ADh3{
    font-size: 30px;
    color: #2e0669;
  }
  
  .ADh4{
    font-size: 15px;
    
  }
  
  .ADsub{
    color: #2e0669;
    font-size:20px;
    font-weight: bold;
  }
  
  .allRightContentHeading {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #2e0669;
  }
  
  
  
  /* .ABOUTmainContainer {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    
    .ABOUTallLeftMenu{
      margin-top: 10px;
      margin-right: 60px;
    }
  
  
    
    .allRightContentHeading {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: #2e0669;
    }
    
    /* Button structure */
    .ABOUTallLeftMenu {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    
    /* Default button style */
    .ABOUTallLeftMenu .menuButton {
      background-color: grey;
      color: white;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      border: none;
      cursor: pointer;
      width: 300px; /* Set a fixed width for your buttons */
      height: 60px; /* Set a fixed height for your buttons */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    /* Hover state */
    .ABOUTallLeftMenu .menuButton:hover {
      background-color: #2e0669;
    }
    
    
    
    /* Selected state */
    .ABOUTallLeftMenu .menuButton.active {
      background-color: #2e0669;
     
    }
    
    .a{
      font-weight: bold;
      font-size: 15px;
      color: rgb(20, 6, 63);
    }
  
    .intro{
      color: #2e0669;
    }
  
    .vision{
      color: #2e0669;
    }
  
    .mission{
      color: #2e0669;
    }
  
    .ss{
      color: #2e0669;
    }
  
    .func{
      color: #2e0669;
    }
  
    .ben{
      color: #2e0669;
    }
    
    .features{
      color: #2e0669;
    }
  
    .mm{
      color: #2e0669;
    }
  
  @media screen and (max-width: 600px) {
    .ABOUT-Main {
      display: flex;
      flex-direction: column;
    }
  }